import React from "react";
import Layout from "../components/Layout";
import SwapList from "../components/SwapList";
import CreateSwapEntry from "../components/CreateSwapEntry";

import { Box } from "@chakra-ui/core";

export default () => (
  <Layout>
    <Box m={4}>
      <SwapList />
    </Box>
    <Box>
      <CreateSwapEntry />
    </Box>
  </Layout>
);
