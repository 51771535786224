import React from "react";
import { Box } from "@chakra-ui/core";
import { Link } from "react-router-dom";

const Sep = () => (
  <Box as="span" color="gray.300">
    {" | "}
  </Box>
);

export default () => (
  <Box borderTop="1px solid" borderTopColor="gray.100" p={4} textAlign="center">
    <Link to="/about">About</Link>
    <Sep />
    <a href="https://github.com/kevholder/otc">GitHub</a>
  </Box>
);
