import React from "react";
import { Box, Text, Heading } from "@chakra-ui/core";

export default ({ error }) => {
  const title = error.name ? `Error: ${error.name}` : "Error";
  return (
    <Box bg="tomato" color="white" p={4}>
      <Heading size="l" mb={2}>
        {title}
      </Heading>
      <Text>{error.message}</Text>
    </Box>
  );
};
