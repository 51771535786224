import React, { useEffect } from "react";
import {
  useToast,
  Box,
  Heading,
  Flex,
  // Text,
  Button,
  // useTheme,
  Badge,
} from "@chakra-ui/core";
import { Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { networks } from "../utils";
import { injectedConnector as connector } from "../connectors";

/*
const MenuItems = ({ children }) => (
  <Text mt={{ base: 4, md: 0 }} mr={6} display="block">
    {children}
  </Text>
);
*/

const Header = (props) => {
  // const [show, setShow] = React.useState(false);
  // const handleToggle = () => setShow(!show);
  const web3React = useWeb3React();
  // const theme = useTheme();
  const toast = useToast();

  const { error } = web3React;
  const network =
    networks.find((c) => c.id === web3React.chainId) || networks[0];

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    web3React.activate(connector);
  }, []);

  useEffect(() => {
    if (!error) return;
    console.error(error);
    toast({ title: error.name, description: error.message, status: "error" });
  }, [error, toast]);

  // TODO: move to toast in App component
  if (web3React.error) {
    console.error(web3React.error);
  }

  const renderWalletInfo = () => {
    if (web3React.account) {
      return (
        <Button
          _hover={{}}
          _active={{}}
          _focus={{}}
          cursor="default"
          size="md"
          color="blue"
          fontFamily="monospace"
        >
          {web3React.account}
        </Button>
      );
    }
    const onClick = () => {
      web3React.activate(connector);
    };
    return (
      <Button variantColor="blue" onClick={onClick}>
        Connect MetaMask
      </Button>
    );
  };

  const renderNetworkInfo = () => {
    if (network.networkName === "mainnet") return;
    return (
      <Box>
        <Badge variantColor="yellow">{network.networkName}</Badge>
      </Box>
    );
  };

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding="1rem"
      // bg="teal.500"
      // color="white"
      borderBottom="1px solid"
      borderBottomColor="gray.100"
      {...props}
    >
      <Flex align="center" mr={5}>
        <Heading as="h1" size="lg">
          <Link to="/">OTC Dex</Link>
        </Heading>
      </Flex>

      {/*
      <Box
        display={{ sm: "block", md: "none" }}
        cursor="pointer"
        onClick={handleToggle}
      >
        <svg
          fill={theme.colors.blue[900]}
          width="12px"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </Box>
      */}

      {/*
      <Box
        display={{ sm: show ? "block" : "none", md: "flex" }}
        width={{ sm: "full", md: "auto" }}
        alignItems="center"
        flexGrow={1}
      >
        <MenuItems>
          <a href="https://github.com/kevholder/otc" target="_blank">
            GitHub
          </a>
        </MenuItems>
      </Box>
        */}

      {renderNetworkInfo()}
      <Box>{renderWalletInfo()}</Box>
    </Flex>
  );
};

export default Header;
