import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Grid } from "@chakra-ui/core";

export default ({ children }) => (
  <Grid
    minH={"100%"}
    gridTemplateRows={"auto 1fr auto"}
    gridTemplateColumns={"100%"}
  >
    <Header />
    <main>{children}</main>
    <Footer />
  </Grid>
);
