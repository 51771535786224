import React, { useState, useEffect } from "react";
import { Flex, Box, Skeleton, Heading, Text } from "@chakra-ui/core";
import exchangeAbi from "../abis/exchange.json";

import SwapEntry from "./SwapEntry";
import ErrorBox from "./ErrorBox";
import useExchangeContract from "../hooks/useExchangeContract";
import useTokens from "../hooks/useTokens";
import { useWeb3React } from "@web3-react/core";
import { zeroAddress } from "../utils";

const swapEntryProps = exchangeAbi
  .find((abi) => abi.name === "swapEntriesArray")
  .outputs[0].components.map((c) => c.name);

const decodeSwapEntriesArray = (entries) => {
  // workaround: https://github.com/ethereum/web3.js/issues/3591
  return entries.map((entry) => {
    return Object.fromEntries(
      entry.map((val, idx) => {
        return [swapEntryProps[idx], val];
      })
    );
  });
};

const EmptyState = () => (
  <Box textAlign="center" padding={4}>
    <Heading size="lg">There are no OTC trade requests at this time.</Heading>
    <Text>You can create the first one using the form below.</Text>
  </Box>
);

const getTokenByAddress = (tokens, address) => {
  return tokens.find((t) => t.contractAddress === address);
};

export default ({ filter = {} } = {}) => {
  const [{ data, pending, error }, setResponse] = useState({ pending: true });
  const tokens = useTokens();

  const exchangeContract = useExchangeContract();
  const web3react = useWeb3React();
  const { account } = web3react;

  // console.log({ exchangeContract });

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const loadData = async () => {
      try {
        const res = await exchangeContract.methods.swapEntriesArray().call();
        // decode array to object and add id property
        const augmentedData = decodeSwapEntriesArray(res).map((e, idx) => ({
          ...e,
          token: getTokenByAddress(tokens, e.erc20Token),
          id: idx,
        }));
        setResponse({ data: augmentedData });
      } catch (err) {
        console.error(err);
        setResponse({ error: err });
      }
    };
    setResponse({ pending: true });
    loadData();
    const timer = setInterval(() => {
      loadData();
    }, 3000);
    return () => clearInterval(timer);
  }, [web3react.chainId]);

  if (error) {
    // todo: retry={retry}
    return <ErrorBox error={error} />;
  }
  if (pending) {
    return (
      <>
        <Skeleton h={10} my={2} />
      </>
    );
  }
  const filteredEntries = data.filter((entry) => {
    if (filter.swapEntryId) {
      return entry.id === filter.swapEntryId;
    }
    // default to fulfillable
    const isFulFillable = !entry.canceled && !entry.fulfilled;
    const isAuthorizedToFulfill = [zeroAddress, account].includes(
      entry.restrictToAddress
    );
    const isMine = entry.maker === account;
    return isFulFillable && (isMine || isAuthorizedToFulfill);
  });
  if (!filteredEntries.length) {
    return <EmptyState />;
  }
  return (
    <Box>
      <Flex>
        {filteredEntries.map((entry) => (
          <Box key={entry.id} mr={4} w={{ base: 1 / 2, md: 1 / 3, lg: 1 / 5 }}>
            <SwapEntry data={entry} />
          </Box>
        ))}
      </Flex>
    </Box>
  );
};
