import React, { useState } from "react";
import { useToast, Box, Button, Icon, Text } from "@chakra-ui/core";
import { useWeb3React } from "@web3-react/core";
import useExchangeContract from "../hooks/useExchangeContract";
import useTokenContract from "../hooks/useTokenContract";
import { formatTokenAmount } from "../utils";
import { Link } from "react-router-dom";

import Web3 from "web3";

const swapTypes = {
  Buy: "0",
  Sell: "1",
};

const {
  utils: { fromWei },
} = Web3;

const CurrencySymbol = ({ symbol }) => <Text display="inline"> {symbol} </Text>;

const useCancelSwap = (data) => {
  const toast = useToast();
  const [pendingCancel, setPendingCancel] = useState(false);
  const exchangeContract = useExchangeContract();

  const onClickCancel = async () => {
    try {
      setPendingCancel(true);
      await exchangeContract.methods.cancelSwapEntry(data.id).send();
      setPendingCancel(false);
      toast({
        title: "Swap canceled",
        description: "Locked funds have been returned to your address",
        status: "success",
      });
    } catch (err) {
      setPendingCancel(false);
      console.error(err);
      toast({
        title: err.name,
        description: err.message,
        status: "error",
      });
    }
  };
  return {
    onClickCancel,
    pendingCancel,
  };
};

const FulfillBuySwapButton = ({ data }) => {
  const toast = useToast();
  const web3react = useWeb3React();
  const [pendingFulfill, setPendingFulfill] = useState(false);
  const [pendingApprove, setPendingApprove] = useState(false);

  const isPending = pendingFulfill || pendingApprove;

  const tokenContract = useTokenContract(data.token);
  const exchangeContract = useExchangeContract();
  const exchangeContractAddress = exchangeContract.options.address;

  const onClick = async () => {
    try {
      // TODO: only wait for receipt after approve?
      // TODO: only approve if not already approved
      setPendingApprove(true);
      const allowance = await tokenContract.methods
        .allowance(web3react.account, exchangeContractAddress)
        .call();
      if (allowance < data.outputAmount) {
        await tokenContract.methods
          .approve(exchangeContractAddress, data.outputAmount)
          .send();
      }
      setPendingApprove(false);
      setPendingFulfill(true);
      const receipt = await exchangeContract.methods
        .fulfillBuySwap(data.id)
        .send();
      toast({
        title: "Swap executed",
        description: `Transaction hash: ${receipt.transactionHash}`,
        status: "success",
      });
    } catch (err) {
      setPendingApprove(false);
      setPendingFulfill(false);
      console.error(err);
      toast({
        title: err.name,
        description: err.message,
        status: "error",
      });
    }
  };

  let loadingText = "";
  if (pendingApprove) {
    loadingText = "Approving token...";
  }
  if (pendingFulfill) {
    loadingText = "Executing swap...";
  }
  // https://github.com/chakra-ui/chakra-ui/issues/576
  const style = {
    whiteSpace: "normal",
    wordWrap: "break-word",
  };
  return (
    <Button
      isLoading={isPending}
      loadingText={loadingText}
      onClick={onClick}
      variant="outline"
      variantColor="blue"
      w={"100%"}
      style={style}
    >
      Swap
    </Button>
  );
};

const FulfillSellSwapButton = ({ data }) => {
  const toast = useToast();
  const [pendingFulfill, setPendingFulfill] = useState(false);

  const exchangeContract = useExchangeContract();

  const onClick = async () => {
    try {
      setPendingFulfill(true);
      const receipt = await exchangeContract.methods
        .fulfillSellSwap(data.id)
        .send({ value: data.outputAmount });
      toast({
        title: "Swap executed",
        description: `Transaction hash: ${receipt.transactionHash}`,
        status: "success",
      });
    } catch (err) {
      setPendingFulfill(false);
      console.error(err);
      toast({
        title: err.name,
        description: err.message,
        status: "error",
      });
    }
  };

  // https://github.com/chakra-ui/chakra-ui/issues/576
  const style = {
    whiteSpace: "normal",
    wordWrap: "break-word",
  };
  return (
    <Button
      isLoading={pendingFulfill}
      loadingText="Executing swap..."
      onClick={onClick}
      variant="outline"
      variantColor="blue"
      w={"100%"}
      style={style}
    >
      Swap
    </Button>
  );
};

export default ({ data }) => {
  const { pendingCancel, onClickCancel } = useCancelSwap(data);

  const web3react = useWeb3React();

  const renderCancelButton = () => {
    return (
      <Button
        isLoading={pendingCancel}
        loadingText="Cancel in progress"
        variant="outline"
        variantColor="red"
        w={"100%"}
        onClick={onClickCancel}
      >
        Cancel
      </Button>
    );
  };

  const renderButton = () => {
    if (!web3react.account) {
      return;
    }

    if (web3react.account === data.maker) {
      return renderCancelButton();
    } else {
      if (data.swapType === swapTypes.Buy) {
        return <FulfillBuySwapButton data={data} />;
      } else {
        return <FulfillSellSwapButton data={data} />;
      }
    }
  };

  const renderInfo = () => {
    if (data.swapType === swapTypes.Buy) {
      return (
        <>
          {formatTokenAmount(data.outputAmount, data.token)}{" "}
          <CurrencySymbol symbol={data.token.symbol} />{" "}
          <Icon name="arrow-forward" size="1.3em" color="gray.300" />{" "}
          {fromWei(data.inputAmount)}
          <CurrencySymbol symbol="ETH" />{" "}
        </>
      );
    } else {
      return (
        <>
          {fromWei(data.outputAmount)}
          <CurrencySymbol symbol="ETH" />{" "}
          <Icon name="arrow-forward" size="1.3em" color="gray.300" />{" "}
          {formatTokenAmount(data.inputAmount, data.token)}{" "}
          <CurrencySymbol symbol={data.token.symbol} />{" "}
        </>
      );
    }
  };

  // TODO: cancel button if mine
  return (
    <Box
      my={4}
      p={2}
      border="1px solid"
      borderRadius={2}
      borderColor="gray.300"
    >
      <Box pb={3} textAlign="center">
        <Link to={`/otc/${data.id}`}>{renderInfo()}</Link>
      </Box>
      <Box>{renderButton()}</Box>
    </Box>
  );
};
