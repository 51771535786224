import React from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  ModalHeader,
  PseudoBox,
} from "@chakra-ui/core";

import useTokens from "../hooks/useTokens";

const TokenRow = ({ token, onClick }) => (
  <PseudoBox
    onClick={onClick}
    cursor="pointer"
    _hover={{ backgroundColor: "blue.50", color: "blue.600" }}
    paddingY={3}
    paddingX="1.5rem"
  >
    {token.symbol} ({token.name})
  </PseudoBox>
);

const TokenSelectorModal = ({ disclosure, setToken }) => {
  const tokens = useTokens();
  const { isOpen, onClose } = disclosure;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius={4}>
        <ModalHeader>Select a token</ModalHeader>
        <ModalCloseButton />
        <ModalBody padding={0}>
          {tokens.map((t) => (
            <TokenRow
              key={t.contractAddress}
              token={t}
              onClick={() => {
                setToken(t);
                onClose();
              }}
            />
          ))}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const TokenSelectorButton = ({ selected, setToken, ...otherProps }) => {
  const disclosure = useDisclosure(false);
  return (
    <>
      <TokenSelectorModal setToken={setToken} disclosure={disclosure} />
      <Button
        {...otherProps}
        rightIcon="chevron-down"
        onClick={() => disclosure.onOpen()}
      >
        {selected.symbol}
      </Button>
    </>
  );
};

export default TokenSelectorButton;
