import React from "react";
import Layout from "../components/Layout";
import SwapList from "../components/SwapList";
import { useParams } from "react-router-dom";

import { Box } from "@chakra-ui/core";

// TODO: write a proper page instead of reusing swaplist...
export default () => {
  const { swapEntryId } = useParams();

  return (
    <Layout>
      <Box m={4}>
        <SwapList filter={{ swapEntryId: parseInt(swapEntryId, 10) }} />
      </Box>
    </Layout>
  );
};
