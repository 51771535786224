import React from "react";
import Layout from "../components/Layout";

import { Box, Text } from "@chakra-ui/core";

export default () => (
  <Layout>
    <Box m={4}>
      <Text>OTC Dex is a smart contract for trustless OTC trading.</Text>
    </Box>
  </Layout>
);
