import React, { useState } from "react";
import Web3 from "web3";
import {
  Text,
  Button,
  Box,
  Input,
  InputGroup,
  Heading,
  useToast,
  Switch,
  FormLabel,
  FormHelperText,
} from "@chakra-ui/core";
import config from "../config";
import TokenSelector from "./TokenSelector";
import { useWeb3React } from "@web3-react/core";
import { zeroAddress, toSmallestDenomination } from "../utils";
import { injectedConnector as connector } from "../connectors";
import useExchangeContract from "../hooks/useExchangeContract";
import useTokenContract from "../hooks/useTokenContract";

const SwapTypeSelector = ({ selected, onSelect }) => {
  const renderButton = (swapType) => {
    const isSelected = selected === swapType;
    const buttonProps = isSelected
      ? {
          bg: "blue.900",
          color: "white",
          cursor: "default",
          _hover: {},
          _active: {},
          _focus: {},
        }
      : {
          onClick: () => {
            onSelect(swapType);
          },
        };
    return <Button {...buttonProps}>{swapType}</Button>;
  };
  return (
    <>
      {renderButton("Buy")} {renderButton("Sell")}
    </>
  );
};

const TokenAmountInput = (props) => {
  return (
    <>
      <InputGroup size="md" display={{ md: "inline-block" }}>
        <Input
          value={props.value}
          onChange={(e) => props.setValue(e.target.value)}
          variant="flushed"
          type="number"
          placeholder="0.0"
        />
      </InputGroup>

      <TokenSelector
        setToken={props.setToken}
        h="1.75rem"
        size="sm"
        {...props}
      />
    </>
  );
};

const EthAmountInput = (props) => {
  // TODO: validate input
  // TODO: MAX button
  return (
    <>
      <InputGroup size="md" display={{ md: "inline-block" }}>
        <Input
          variant="flushed"
          type="number"
          placeholder="0.0"
          value={props.value}
          onChange={(e) => {
            props.setValue(e.target.value);
          }}
        />
      </InputGroup>
      <Button
        h="1.75rem"
        size="sm"
        cursor="default"
        _hover={{}}
        _focus={{}}
        _active={{}}
      >
        ETH
      </Button>
    </>
  );
};

export const CreateBuySwapButton = ({
  disabled,
  ethAmount,
  tokenAmount,
  token,
  restrictToAddress,
  onSuccess,
}) => {
  const [{ pending }, setResponse] = useState({});
  const exchangeContract = useExchangeContract();
  const toast = useToast();

  const onClick = async () => {
    let txHash;
    let receipt;
    try {
      // console.log({ exchangeContract });
      const value = Web3.utils.toWei(ethAmount);

      const outputAmount = toSmallestDenomination(tokenAmount, token);
      // console.log({ value, outputAmount });
      console.log({ outputAmount });
      setResponse({ pending: true });
      receipt = await exchangeContract.methods
        .createBuySwapEntry(
          token.contractAddress,
          outputAmount,
          restrictToAddress
        )
        .send({
          // from: accounts[1],
          value,
        });
      txHash = receipt.transactionHash;
      setResponse({ data: receipt });

      toast({
        title: "OTC Entry Created",
        description: `Transaction: ${txHash}`,
        status: "success",
      });
      onSuccess();
    } catch (error) {
      setResponse({ pending: false });
      console.error(error);
      toast({
        title: error.name,
        description: error.message,
        status: "error",
      });
    }
  };
  return (
    <Button
      isLoading={pending}
      loadingText="Waiting for transaction confirmation..."
      disabled={disabled}
      variantColor="blue"
      onClick={onClick}
    >
      Create OTC Request
    </Button>
  );
};

export const CreateSellSwapButton = ({
  disabled,
  ethAmount,
  tokenAmount,
  token,
  restrictToAddress,
  onSuccess,
}) => {
  const [pendingCreate, setPendingCreate] = useState(false);
  const [pendingApprove, setPendingApprove] = useState(false);

  const web3react = useWeb3React();
  const exchangeContract = useExchangeContract();
  const tokenContract = useTokenContract(token);

  const exchangeContractAddress = exchangeContract.options.address;

  const toast = useToast();

  const onClick = async () => {
    let txHash;
    let receipt;
    try {
      const inputAmount = toSmallestDenomination(tokenAmount, token);
      const outputAmount = Web3.utils.toWei(ethAmount);

      setPendingCreate(true);
      setPendingApprove(true);
      const allowance = await tokenContract.methods
        .allowance(web3react.account, exchangeContractAddress)
        .call();
      console.log({ allowance, inputAmount });
      if (allowance < inputAmount) {
        await tokenContract.methods
          .approve(exchangeContractAddress, inputAmount)
          .send();
      }
      setPendingApprove(false);

      console.log({ inputAmount, outputAmount });
      receipt = await exchangeContract.methods
        .createSellSwapEntry(
          token.contractAddress,
          inputAmount,
          outputAmount,
          restrictToAddress
        )
        .send({});
      txHash = receipt.transactionHash;
      setPendingCreate(false);

      toast({
        title: "OTC Entry Created",
        description: `Transaction: ${txHash}`,
        status: "success",
      });
      onSuccess();
    } catch (error) {
      setPendingApprove(false);
      setPendingCreate(false);
      console.error(error);
      toast({
        title: error.name,
        description: error.message,
        status: "error",
      });
    }
  };

  let loadingText = "Waiting for transaction confirmation...";
  if (pendingApprove) {
    loadingText = "Approving token...";
  }

  const pending = pendingApprove || pendingCreate;

  return (
    <Button
      isLoading={pending}
      loadingText={loadingText}
      disabled={disabled}
      variantColor="blue"
      onClick={onClick}
    >
      Create OTC Request
    </Button>
  );
};

export default () => {
  const [swapType, setSwapType] = useState("Buy");
  const [ethAmount, setEthAmount] = useState("");
  const [tokenAmount, setTokenAmount] = useState("");
  const [isPrivate, setIsPrivate] = useState(false);
  const [restrictToAddress, setRestrictToAddress] = useState("");
  const web3react = useWeb3React();

  const resetForm = () => {
    setEthAmount("");
    setTokenAmount("");
    setIsPrivate(false);
    setRestrictToAddress("");
  };

  // todo; useNetworkConfig which returns right config depending on
  // web3react network / default to mainnet
  const networkName = "ropsten";
  const tokens = config.networks[networkName].knownTokens;

  const [token, setToken] = useState(tokens[0]);

  const ethAmountFloat = parseFloat(ethAmount);
  const tokenAmountFloat = parseFloat(tokenAmount);

  const restrictToAddress_ = isPrivate ? restrictToAddress : zeroAddress;

  const isFormValid =
    ethAmountFloat > 0 &&
    tokenAmountFloat > 0 &&
    Web3.utils.isAddress(restrictToAddress_);

  const renderButton = () => {
    if (!web3react.account) {
      const onClick = () => {
        web3react.activate(connector);
      };
      return (
        <Button variantColor="blue" onClick={onClick}>
          Connect MetaMask
        </Button>
      );
    } else {
      if (swapType === "Buy") {
        return (
          <CreateBuySwapButton
            ethAmount={ethAmount}
            tokenAmount={tokenAmount}
            disabled={!isFormValid}
            restrictToAddress={restrictToAddress_}
            token={token}
            onSuccess={resetForm}
          />
        );
      } else {
        return (
          <CreateSellSwapButton
            ethAmount={ethAmount}
            tokenAmount={tokenAmount}
            disabled={!isFormValid}
            restrictToAddress={restrictToAddress_}
            token={token}
            onSuccess={resetForm}
          />
        );
      }
    }
  };

  return (
    <Box p={4} m={4} border="1px solid" borderRadius={2} borderColor="gray.100">
      <Heading mb={4} size="l">
        Create an OTC Request
      </Heading>
      <Box>
        <Text display="inline">I want to </Text>
        <SwapTypeSelector selected={swapType} onSelect={setSwapType} />{" "}
        <TokenAmountInput
          selected={token}
          setToken={setToken}
          value={tokenAmount}
          setValue={setTokenAmount}
        />
        <Text display={{ md: "inline" }} my={{ base: 2, md: 0 }}>
          {" "}
          for{" "}
        </Text>
        <EthAmountInput value={ethAmount} setValue={setEthAmount} />
        <Box mt={4}>
          <Switch
            id="private-switch"
            value={isPrivate}
            onChange={(e) => setIsPrivate(e.target.checked)}
          />{" "}
          <FormLabel htmlFor="private-switch">Private </FormLabel>
          {isPrivate && (
            <>
              <Input
                placeholder="0x..."
                onChange={(e) => setRestrictToAddress(e.target.value)}
                value={restrictToAddress}
              />
              <FormHelperText>
                Only this address will be allowed to execute the trade.
              </FormHelperText>
            </>
          )}
        </Box>
        <Box mt={4}>{renderButton()}</Box>
      </Box>
    </Box>
  );
};
