import numbro from "numbro";
// import Web3 from "web3";
import bn from "bignumber.js";

numbro.setDefaults({
  thousandSeparated: true,
});

/*
const {
  utils: { toBN },
} = Web3;
*/

// like fromWei
export const fromSmallestDenomination = (amount, token) => {
  const decimals = token.decimals || 18;
  const n = bn(amount).div(bn(10).pow(bn(decimals)));
  return n;
};

// like toWei
export const toSmallestDenomination = (amount, token) => {
  const decimals = token.decimals || 18;
  const n = bn(amount).times(bn(10).pow(bn(decimals)));
  return n;
};

export const formatTokenAmount = (amount, token) => {
  const bn = fromSmallestDenomination(amount, token);
  return numbro(bn.toString()).format();
};

export const networks = [
  { id: 1, networkName: "mainnet" },
  { id: 3, networkName: "ropsten" },
  { id: 4, networkName: "rinkeby" },
  { id: 5, networkName: "gorli" },
  { id: 42, networkName: "kovan" },
];

export const zeroAddress = "0x0000000000000000000000000000000000000000";
