import contractAddresses from "@otc/solidity";

export default {
  networks: {
    ropsten: {
      contractAddresses: contractAddresses.ropsten,
      knownTokens: [
        // TODO: copy list from uniswap?
        {
          name: "TestToken",
          symbol: "TST",
          contractAddress: contractAddresses.ropsten.TestToken,
          decimals: 18,
        },
        {
          name: "Yeenus",
          symbol: "YEENUS",
          // https://github.com/bokkypoobah/WeenusTokenFaucet
          contractAddress: "0xF6fF95D53E08c9660dC7820fD5A775484f77183A",
          decimals: 8,
        },
      ],
    },
  },
};
