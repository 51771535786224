import React from "react";
import { ThemeProvider, CSSReset } from "@chakra-ui/core";
import customTheme from "./theme";
import Web3 from "web3";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// Use at the root of your app

import { Web3ReactProvider } from "@web3-react/core";
// import your favorite web3 convenience library here

import Home from "./pages/Home";
import About from "./pages/About";
import SwapEntryPage from "./pages/SwapEntryPage";

function getLibrary(provider, connector) {
  // TODO: default to mainnet network?
  const library = new Web3(provider);
  return library;
}

// TODO: routing
function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <CSSReset />
      <Web3ReactProvider getLibrary={getLibrary}>
        <Router>
          <Switch>
            {/*
          <Route path="/otc">
            <About />
          </Route>
          */}
            <Route path="/otc/:swapEntryId">
              <SwapEntryPage />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      </Web3ReactProvider>
    </ThemeProvider>
  );
}

export default App;
