import Web3 from "web3";
import exchangeAbi from "../abis/exchange.json";
import config from "../config";
import { useWeb3React } from "@web3-react/core";

const useExchangeContract = () => {
  const web3react = useWeb3React();
  // const web3 = web3react.library;
  // TODO: dont hardcode address
  // TODO: use righ network
  // console.log("web3react", web3react);
  // TODO: default to mainnet
  const web3 =
    web3react.library || new Web3("https://api.infura.io/v1/jsonrpc/ropsten");
  // console.log("web3", web3react);

  const contractAddress = config.networks.ropsten.contractAddresses.Exchange;
  const contract = new web3.eth.Contract(
    exchangeAbi,
    // TODO: use righ network
    contractAddress,
    { from: web3react.account }
  );
  return contract;
};

export default useExchangeContract;
